<template>
  <base-layout-two
    :page-title="`New Order`"
    :page-default-back-link="`/users/${$route.params.id}/folios`"
    content-class="background-white"
  >
    <section class="padding-x-16 padding-bottom-16">
      <h1>Order</h1>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="12" size-lg="6">
            <ion-button
              fill="outline"
              expand="block"
              @click="showPurchaseCreateModal = true"
              >Add Purchase
            </ion-button>
          </ion-col>

          <ion-col size="12" size-lg="6">
            <ion-button
              fill="outline"
              expand="block"
              @click="showLineItemCreateModal = true"
              >Add Custom Line Item
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <ion-list>
      <!-- Line Items -->
      <ion-item-group>
        <ion-item-divider>
          <ion-label> Line Items </ion-label>
        </ion-item-divider>

        <transaction-list-item
          v-for="transaction in folio.transactions"
          :key="transaction.id"
          :transaction="transaction"
        >
        </transaction-list-item>
      </ion-item-group>

      <!-- Totals -->
      <ion-item-group>
        <ion-item-divider>
          <ion-label> Totals </ion-label>
        </ion-item-divider>

        <transaction-list-item :transaction="subtotal"> </transaction-list-item>

        <transaction-list-item :transaction="taxesAndFees">
        </transaction-list-item>

        <transaction-list-item :transaction="total"> </transaction-list-item>
      </ion-item-group>
    </ion-list>

    <ion-button class="margin-y-16 margin-x-8" expand="block" @click="createFolio">
      Save Order
    </ion-button>

    <!-- Purchase Create Modal -->
    <ion-modal
      :is-open="showPurchaseCreateModal"
      @didDismiss="showPurchaseCreateModal = false"
    >
      <purchase-create-modal
        @add-purchase="addTransaction"
        @cancel-modal="() => (this.showPurchaseCreateModal = false)"
      >
      </purchase-create-modal>
    </ion-modal>

    <!-- Line Item Create Modal -->
    <ion-modal
      :is-open="showLineItemCreateModal"
      @didDismiss="showLineItemCreateModal = false"
    >
      <line-item-create-modal
        @add-line-item="addTransaction"
        @cancel-modal="() => (this.showLineItemCreateModal = false)"
      >
      </line-item-create-modal>
    </ion-modal>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonButton,
} from "@ionic/vue";
import LineItemCreateModal from "@/components/users/folios/LineItemCreateModal.vue";
import PurchaseCreateModal from "@/components/users/folios/PurchaseCreateModal.vue";
import TransactionListItem from "@/components/users/folios/TransactionListItem.vue";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonButton,
    IonModal,
    LineItemCreateModal,
    PurchaseCreateModal,
    TransactionListItem,
  },
  data() {
    return {
      folio: {
        user_id: this.$route.params.id,
        transactions: [],
      },
      showPurchaseCreateModal: false,
      showLineItemCreateModal: false,
    };
  },

  computed: {
    subtotal() {
      let subtotal = this.folio.transactions.reduce(
        (partial_sum, a) => partial_sum + a.price_final,
        0
      );

      let transaction = {
        description: "Subtotal",
        amount: Number(subtotal),
      };

      return transaction;
    },

    taxesAndFees() {
      let taxesAndFees = this.folio.transactions.reduce(
        (partial_sum, a) => partial_sum + a.price_taxes,
        0
      );

      let transaction = {
        description: "Taxes & Fees",
        amount: Number(taxesAndFees),
      };

      return transaction;
    },

    total() {
      let total = this.folio.transactions.reduce(
        (partial_sum, a) => partial_sum + a.price_total,
        0
      );

      let transaction = {
        description: "Total",
        amount: Number(total),
      };

      return transaction;
    },
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    addTransaction(transaction) {
      this.showPurchaseCreateModal = false;
      this.showLineItemCreateModal = false;

      this.folio.transactions.push(transaction);
    },

    async createFolio() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/folios`,
        data: this.folio,
      };

      console.log(config.data)

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.folio = response.data.folio;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "New Order Created", color: "secondary" });

          this.$router.replace(
            `/users/${this.$route.params.id}/folios/${this.folio.id}`
          );
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>